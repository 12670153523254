import React, { useContext, useRef } from 'react';

import { AppContext } from '../../contexts/AppContext/AppContext';

import FullSizeVideoBox from '../user-video-box/FullSizeVideoBox';
import SmallSizeVideoBox from '../user-video-box/SmallSizeVideoBox';

function DualVideoLayout(classNames) {
  const localVideoRef = useRef();
  const guestVideoRef = useRef();
  
  const { 
    focusedUserVideo,
    focusLocalUserVideo,
    focusGuestUserVideo,
    splitUserVideos,
    myName,
    guestName,
    peerConnection
  } = useContext(AppContext);

  const handleFullSizeVideoClick = () => {
    splitUserVideos();
  }

  const handleSmallSizeVideoClick = () => {
    if (focusedUserVideo === 'local') {
      focusGuestUserVideo();
      localVideoRef.current.classList.add('hidden');
      guestVideoRef.current.classList.remove('hidden');
    } else {
      focusLocalUserVideo();
      localVideoRef.current.classList.remove('hidden');
      guestVideoRef.current.classList.add('hidden');
    }
    
  }

  return (
    <div className="relative flex flex-wrap content-center justify-center w-full h-full video-area">

        <FullSizeVideoBox
          user={ 'local' }
          name={ myName }
          color="bg-primary"
          onClickVideo={ splitUserVideos }
          ref={ localVideoRef }
          classNames={ '' }
        >
          { peerConnection &&
            <div className='absolute top-3 right-3 w-auto justify-end flex'>
              <SmallSizeVideoBox
                user={ 'guest' }
                name={ guestName }
                color="bg-primary"
                onClickVideo={ handleSmallSizeVideoClick }
              />
            </div>
          }
        </FullSizeVideoBox>

        { /* 2 */ }
        <FullSizeVideoBox
          user={ 'guest' }
          name={ guestName }
          color="bg-primary"
          onClickVideo={ splitUserVideos }
          ref={ guestVideoRef }
          classNames={ 'hidden' }
        >
          { peerConnection &&
            <div className='absolute top-3 right-3 w-auto justify-end flex'>
              <SmallSizeVideoBox
                user={ 'local' }
                name={ myName }
                color="bg-primary"
                onClickVideo={ handleSmallSizeVideoClick }
              />
            </div>
          }
        </FullSizeVideoBox>

    </div>
  );
}

export default DualVideoLayout;